import React from "react"
import DropBg from "../../UI/DropBg"
import SmallDropFooter from "../../../images/case_studies/enel/SmallDropFooter.svg"
import {FormattedMessage} from "react-intl"
import {Heading, Paragraph} from "../../UI/Typography"
import Link from "../../Link"
import {Button} from "../../UI/Button"
import Drop from "../../UI/Drop"
import * as classes from "./index.module.scss"

const NextSuccessStory = ({title, desc, link, intl}) => {
    return (
        <div className={classes.root}>
            <Drop
                width={525}
                style={{
                    position: "absolute",
                    bottom: -405,
                    left: -215,
                    transform: "rotate(90deg)"
                }}
                className="d-none d-md-block"
            />
            <DropBg
                src={SmallDropFooter}
                bottom="80px"
                left="130px"
                className="d-none d-md-block"
            />
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-8 offset-lg-2">
                        <label className="special-label">
                            <FormattedMessage id="insieme.next_success_case"/>
                        </label>
                        <Heading level="2" margin="40px 0 24px">
                            <FormattedMessage id={title}/>
                        </Heading>
                        <Paragraph margin="0px 0px 96px">
                            <FormattedMessage id={desc}/>
                        </Paragraph>
                        <div className="d-flex justify-content-center">
                            <Link className="link-no-decoration" to={link}>
                                <Button
                                    type="primary"
                                    arrow
                                    label={intl.formatMessage({id: "insieme.next_success_case_btn"})}
                                />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NextSuccessStory
