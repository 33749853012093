import React, {useState} from "react";
import styled, {keyframes} from "styled-components";
import PropTypes from "prop-types";
import {ArrowIcon} from '../../images/icons';
import Spinner from "../Spinner";

const arrowAnimation = keyframes`
    from   { transform:translateX(0px); }
    to     {transform:translateX(5px); }

`

const ButtonStyled = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    cursor: pointer;
    height :46px;
    font-size: 14px;
    font-family: 'Proxima Nova', sans-serif;
    font-weight: 700;
    letter-spacing: 1.225px;
    text-transform: uppercase;
    text-align: center;
    padding: ${(props) => (props.nopadding ? "0 !important" : props.transparent ? props.theme.zeroLeftPadding : props.theme.padding)};
    background:${(props) => props.type ? props.theme.button[props.type].background : 'transparent'};
    color: ${(props) => props.type ? props.theme.button[props.type].color : props.theme.colors.violetDarken200};
    border-radius: 40px;
    border:${(props) => props.type && props.theme.button[props.type].border};
    border-width: ${(props) => props.whiteborder && "2px"};
    border-style: ${(props) => props.whiteborder && "solid"};
    width: max-content;
    max-width: 95vw;
    margin: ${(props) => props.centered ? "auto" : "0"};
    -webkit-transition: all 0.35s  linear;
    -moz-transition: all 0.35s  linear;
    -o-transition: all 0.35s linear;
    transition: all 0.35s  linear;
    z-index: 1;
    svg{
        margin-left: 24px;
        path{
            fill: ${(props) => props.type ? props.theme.button[props.type].arrowColor : '#B84FF8'}
        }
    }
    &:hover:enabled {
        background:${(props) => props.type ? props.theme.button[props.type].backgroundHover : 'transparent'};
        color:${(props) => props.type ? props.theme.button[props.type].colorHover : props.theme.colors.violetDarken200};
        border:${(props) => props.type && props.theme.button[props.type].borderHover};
            svg {
                animation:${arrowAnimation} 0.4s infinite alternate;
                path {
                    fill: ${(props) => props.type ? props.theme.button[props.type].colorHover : '#B84FF8'};
                }
            }
    }
    &:disabled{
        cursor: not-allowed;
        opacity: 0.5
    }
`;

const LabelStyled = styled.span`
    line-height: initial;
`;

const Button = ({arrow, type, whiteborder, nopadding, label, customArrowClass, id, disabled, sending, centered, ...rest}) => {
    const [hovered, setHovered] = useState(false);

    return (
        <ButtonStyled nopadding={nopadding ? nopadding : null} type={type} id={id} disabled={disabled}
                      whiteborder={whiteborder ? whiteborder : null} onMouseOver={() => setHovered(!hovered)}
                      onMouseOut={() => setHovered(!hovered)} centered={centered} {...rest}>
            <LabelStyled>{label}</LabelStyled>
            {sending && <Spinner/>}
            {(arrow && !sending) && <ArrowIcon className={customArrowClass}/>}
        </ButtonStyled>
    );
};

Button.propTypes = {
    primary: PropTypes.bool,
    arrow: PropTypes.bool,
    label: PropTypes.string,
    icon: PropTypes.string,
};

export {Button};
