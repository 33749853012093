import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import useWindowSize from "../../hooks/useWindowSize"

const FullWidthImage = ({
    image,
    imageWidth,
    imageHeight,
    ...rest
}) => {
    const {width: pageWidth, height: pageHeight} = useWindowSize()
    const [height,setHeight] = useState(400)

    useEffect(() => {
        setHeight((imageHeight * pageWidth) / imageWidth)
    },[pageHeight, pageWidth])

    return (
        <div
            style={{
                background: `url("${image}") no-repeat`,
                backgroundSize: (
                    height <= (pageHeight * 0.8) && height >= 300
                        ? "100% auto"
                        : "cover"
                ),
                backgroundPosition: "center",
                width: "100%",
                height: (
                    height <= (pageHeight * 0.8) && height >= 300
                        ? height
                        : height >= 300
                            ? pageHeight * 0.8
                            : 300
                )
            }}
            {...rest}
        />
    )
}

export default FullWidthImage

FullWidthImage.propTypes = {
    image: PropTypes.any,
    imageWidth: PropTypes.number,
    imageHeight: PropTypes.number
}
