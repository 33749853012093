import React from "react";
import styled from "styled-components";
import withLayout from "../../layout";
import {FormattedMessage, injectIntl} from "react-intl";
//GENERIC STYLE IMPORT
import {Heading, Paragraph} from "../../components/UI/Typography";
import DropBottom from '../../images/case_studies/translated/DropBottom.svg';
import DropUp from '../../images/case_studies/translated/DropUp.svg';
import BKGImg from "../../images/case_studies/insieme/Banner-insieme.jpg";
import HeroCaseStudy from "../../components/HeroCaseStudy";
import DropBg from "../../components/UI/DropBg";
import DropBig1 from '../../images/case_studies/acea/DropBig1.svg';
import DropBig2 from '../../images/case_studies/acea/DropTopLeftM.svg';
import SEO from "../../components/SEO";
import SolutionsImg3 from "../../images/case_studies/insieme/logo_anim.mp4";
import Video from "../../images/case_studies/insieme/preent.mp4";
import {Fade} from 'react-reveal';
import Image from "../../components/UI/Image";
import useWindowSize from "../../hooks/useWindowSize";
import FullWidthMessage from "../../components/CaseStudy/FullWidthMessage";
import NextSuccessStory from "../../components/CaseStudy/NextSuccessStory";
import SectionTitle from "../../components/CaseStudy/SectionTitle";
import FullWidthImage from "../../components/CaseStudy/FullWidthImage"

const GradientCit = styled.h2`
      margin: 0;
      padding-top: 136px;
      background: -webkit-linear-gradient(#41B9E6,#1B527F);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
        font-size: 40px;
        line-height: 140%;
        letter-spacing: 1.13px;
      @media screen and (max-width:768px){
           padding-top: 0;
           font-size: 24px;
           line-height: 40px;
        }
`;

const InsiemeCaseStudy = ({intl}) => {
    const keywords = intl.formatMessage({id: 'insieme.meta_keywords'}).split(',') || [];
    const {width: pageWidth} = useWindowSize();

    return (
        <>
            <SEO title={intl.formatMessage({id: 'insieme.meta_title'})}
                 description={intl.formatMessage({id: 'insieme.meta_description'})} keywords={keywords}
                 lang={intl.locale}/>

            <HeroCaseStudy hasPartner={false} heroImage={'case_studies/insieme/Header-insieme.png'} title={'insieme.title'}
                           subtitle1={'insieme.subtitle'} intl={intl}
                           color={'linear-gradient(90deg, #1B527F 0%, #41B9E6  100%)'}
                           categories={['portfolio.filters.design_dev']}/>
            <div className="section-padding">
                <DropBg src={DropBig1} left={'0%'} zIndex={'-1'} bottom={'-20%'} className="d-none d-lg-block"/>
                <DropBg src={DropUp} bottom={'5%'} left={'25%'} className="d-none d-lg-block"/>
                <div className="container">
                    <div className="row">
                        <Fade bottom delay={200}>
                            <div className="col-12">
                                <SectionTitle number={'01'} text={'insieme.overview_title'}/>
                            </div>
                        </Fade>
                    </div>
                    <div className="row">
                        <div className="col-md-5 col-12">
                            <Fade bottom delay={200}>
                                <Heading level={"3"}>
                                    <FormattedMessage id={"insieme.client_title"}/>
                                </Heading>
                            </Fade>
                            <Fade bottom delay={400}>
                                <Paragraph className="pt-2">
                                    <FormattedMessage id={"insieme.client_1_a"}/>
                                </Paragraph>
                                <Paragraph className="pt-2">
                                    <FormattedMessage id={"insieme.client_1_b"}/>
                                </Paragraph>
                            </Fade>
                        </div>
                        <div className={"col-md-6 col-12 offset-md-1"}>
                            <Fade bottom delay={600}>
                                <Heading level={"4"} className="pt-2">
                                    <FormattedMessage id={"insieme.client_needs"}/>
                                </Heading>
                            </Fade>
                            <Fade bottom delay={800}>
                                <Paragraph className="pt-3">
                                    <FormattedMessage id={"insieme.client_needs_1_a"}/>
                                </Paragraph>
                                <Paragraph className="pt-3">
                                    <FormattedMessage id={"insieme.client_needs_1_b"}/>
                                </Paragraph>
                                <Paragraph className="pt-3">
                                    <FormattedMessage id={"insieme.client_needs_1_c"}/>
                                </Paragraph>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
            <FullWidthImage image={BKGImg} imageWidth={1920} imageHeight={1080}/>
            <div className="section-padding">
                <DropBg src={DropBig2} right={'-10%'} zIndex={'-1'} top={'15%'} className="d-none d-lg-block"/>
                <DropBg src={DropBottom} top={'10%'} right={'10%'} className="d-none d-lg-block"/>
                <div className="container">
                    <div className="row">
                        <Fade bottom delay={400}>
                            <div className="col-md-2 d-none d-md-block">
                                <SectionTitle number={'02'} text={'insieme.brief_title'}/>
                            </div>
                        </Fade>
                        <Fade bottom delay={800}>
                            <div className="col-md-8 col-xs-12">
                                <Heading level={"2"} margin={'0px'}>
                                    <FormattedMessage id={"insieme.service_big_title"}/>
                                </Heading>
                                <Paragraph className="pt-2">
                                    <FormattedMessage id="insieme.services_txt_1"/>
                                </Paragraph>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <Fade bottom delay={200}>
                        <div className="col-12">
                            <SectionTitle number={'03'} text={'insieme.workflow_title'}/>
                        </div>
                    </Fade>
                </div>
                <div className="row d-flex align-items-center">
                    <Fade bottom delay={400}>
                        <div className="col-md-6 col-sm-12">
                            <div className="col-12 p-0">
                                <Heading level={"3"}>
                                    <FormattedMessage id={"insieme.solutions_title_1"}/>
                                </Heading>
                            </div>
                            <Paragraph>
                                <FormattedMessage id={"insieme.solutions_txt_1_a"}/>
                            </Paragraph>
                            <Paragraph>
                                <FormattedMessage id={"insieme.solutions_txt_1_b"}/>
                            </Paragraph>
                        </div>
                    </Fade>
                    <Fade bottom delay={800}>
                        <div className="col-md-5 col-sm-12 offset-md-1">
                            <Image filename={'case_studies/insieme/Insieme-macbookpsd.jpg'} alt={'Insieme macbook'}
                                   style={{width: pageWidth < 768 ? '100%' : '180%'}}/>
                        </div>
                    </Fade>
                </div>
            </div>
            <div className="section-padding-custom">
                <div className="container">
                    <div className="row align-items-start">
                        <Fade bottom delay={400}>
                            <div className="col-md-5 col-sm-12 d-md-block d-none position-relative">
                                <Image filename={'case_studies/insieme/telefono_insieme.jpg'} alt={'Insieme telephone'}
                                       className={'case-study-left-pic'}/>
                            </div>
                        </Fade>
                        <Fade bottom delay={800}>
                            <div className="col-md-6 col-sm-12 offset-md-1">
                                <div className="col-12 p-0">
                                    <Heading level={"3"}>
                                        <FormattedMessage id={"insieme.solutions_title_2"}/>
                                    </Heading>
                                </div>
                                <Paragraph>
                                    <FormattedMessage id={"insieme.solutions_txt_2"}/>
                                </Paragraph>
                                <Paragraph>
                                    <FormattedMessage id={"insieme.solutions_txt_2_a"}/>
                                </Paragraph>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
            <Fade bottom delay={400}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-8 offset-md-4">
                            <GradientCit>
                                <FormattedMessage id={"insieme.citation"}/>
                            </GradientCit>
                        </div>
                    </div>
                </div>
            </Fade>

            <div className="section-padding">
                <Fade bottom delay={400}>
                    <div className="container">
                        <div className="row d-flex align-items-center">
                            <div className="col-md-6 col-sm-12">
                                <div className="col-12 p-0">
                                    <Heading level={"3"}>
                                        <FormattedMessage id={"insieme.solutions_title_3"}/>
                                    </Heading>
                                </div>
                                <Paragraph>
                                    <FormattedMessage id={"insieme.solutions_txt_3"}/>
                                </Paragraph>
                            </div>
                            <div className='col-md-1 d-md-block d-none'/>
                            <div className="col-md-5 col-sm-12">
                                <div dangerouslySetInnerHTML={{
                                    __html: `
                                       <video
                                        width="100%"
                                         loop
                                         muted
                                         autoplay
                                         playsinline
                                         webkit-playsinline
                                         type="video/mp4"
                                         src="${SolutionsImg3}""
                                       />,
                                     `
                                }}/>
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>
            <Fade bottom delay={400}>
                <div className="container d-md-block d-none">
                    <div className="row">
                        <div className="col-12">
                            <div dangerouslySetInnerHTML={{
                                __html: `
                                       <video
                                        width="100%"
                                         loop
                                         muted
                                         autoplay
                                         playsinline
                                         webkit-playsinline
                                         type="video/mp4"
                                         src="${Video}""
                                       />,
                                     `
                            }}/>
                        </div>
                    </div>
                </div>
            </Fade>
            <div className="section-padding-custom">
                <Fade bottom delay={400}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <Heading level={"3"}>
                                    <FormattedMessage id={"insieme.solutions_title_4"}/>
                                </Heading>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <Paragraph>
                                    <FormattedMessage id={"insieme.solutions_txt_4_a"}/>
                                </Paragraph>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <Paragraph>
                                    <FormattedMessage id={"insieme.solutions_txt_4_b"}/>
                                </Paragraph>
                            </div>
                        </div>

                        <div className="col-12 d-md-none d-block mt-5">
                            <div dangerouslySetInnerHTML={{
                                __html: `
                                       <video
                                        width="100%"
                                         loop
                                         muted
                                         autoplay
                                         playsinline
                                         webkit-playsinline
                                         type="video/mp4"
                                         src="${Video}""
                                       />,
                                     `
                            }}/>
                        </div>
                    </div>
                </Fade>
            </div>
            <FullWidthMessage isBig intl={intl}/>
            <NextSuccessStory
                title="insieme.next_success_case_title"
                desc="insieme.next_success_case_description"
                link="/case-study/mantegna/"
                intl={intl}
            />
        </>
    )
}

const customProps = {
    localeKey: "insieme", // same as file name in src/i18n/translations/your-lang/index.js
}

export default withLayout(customProps)(injectIntl(InsiemeCaseStudy))
