import React from 'react'
import Header from '../Header'
import Dash from '../../components/UI/Dash'
import ArrowDark from '../../images/arrow_dark.svg'
import {FormattedMessage} from 'react-intl'
import {Fade} from "react-reveal"
import Image from "../UI/Image"
import {Heading} from "../UI/Typography"
import FullWidthMessage from "../CaseStudy/FullWidthMessage"
import Drop from "../UI/Drop"
import * as classes from "./index.module.scss"
import cx from "classnames"
import useWindowSize from "../../hooks/useWindowSize"

const HeroCaseStudy = ({
    isBig,
    color,
    heroImage,
    categories = [],
    title,
    stats,
    subtitle1,
    subtitle2,
    hasPartner,
    partnerLogo,
    intl
}) => {
    const scrollDown = () => window?.document?.getElementById("01").scrollIntoView({behavior: "smooth"})
    const isTablet = useWindowSize().width < 992

    return (
        <>
            <Header headerStyle="light"/>
            <div className={cx(classes.root, "container")}>
                <div className="row flex-lg-row flex-column">
                    <div className="col-lg-6 col-12 order-2 order-lg-1">
                        <Fade bottom delay={isTablet ? 600 : 200}>
                            <div className={classes.categories}>
                                {categories.map((category, index) => (
                                    <span key={index}>
                                        <FormattedMessage id={category}/>
                                    </span>
                                ))}
                            </div>
                        </Fade>
                        <Fade bottom delay={isTablet ? 800 : 600}>
                            <div>
                                <Dash background={color} margin="38px 0px 30px"/>
                                <Heading level="1" className={classes.title}>
                                    <FormattedMessage id={title}/>
                                </Heading>
                            </div>
                        </Fade>
                        <Fade bottom delay={1000}>
                            <div>
                                {stats}
                                <Heading level="2" className={classes.subtitle}>
                                    <span>
                                        <FormattedMessage id={subtitle1}/>
                                    </span>
                                    <span>
                                        {subtitle2 && <FormattedMessage id={subtitle2}/>}
                                    </span>
                                </Heading>
                            </div>
                        </Fade>
                        <Fade bottom delay={1200}>
                            {hasPartner && <div className={classes.partners}>
                                <p>
                                    <FormattedMessage id={'enel.collaboration'}/>
                                </p>
                                <img src={partnerLogo} alt="logo partner"/>
                            </div>}
                        </Fade>
                        <div className={cx(classes.scrollArrow, "bounce")} onClick={scrollDown}>
                            <img src={ArrowDark} alt="scroll down"/>
                        </div>
                    </div>
                    <Fade bottom delay={isTablet ? 200 : 400}>
                        <div className="col-lg-4 offset-lg-2 col-12 order-1 order-lg-2">
                            <Image
                                filename={heroImage}
                                alt="Hero image"
                                className={classes.image}
                            />
                        </div>
                    </Fade>
                </div>
                <Drop
                    width={480}
                    fill="#F4F5F8"
                    style={{
                        position: "absolute",
                        left: "40%",
                        bottom: -80,
                        zIndex: -1,
                        transform: "rotate(180deg)"
                    }}
                    className="d-none d-lg-block"
                />
            </div>
            <Fade bottom delay={1400}>
                <FullWidthMessage
                    isBig={isBig}
                    color={color}
                    intl={intl}
                />
            </Fade>
        </>
    )
}

export default HeroCaseStudy
