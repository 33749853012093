import React from "react"
import {FormattedMessage} from "react-intl"
import Link from "../../Link"
import {Button} from "../../UI/Button"
import DropBg from "../../UI/DropBg"
import TransparentDrop from "../../../images/transparent_drop.png"
import * as classes from "./index.module.scss"
import cx from "classnames"

const FullWidthMessage = ({
    isBig = false,
    color= "linear-gradient(180deg, #A944F5 0%, #9938F2 100%)",
    intl
}) => {
    return (
        <div
            className={cx(
                classes.root,
                isBig ? classes.big : classes.small
            )}
            style={{background: color}}
        >
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-7 col-md-12 col-12 d-flex">
                        <h3>
                            <FormattedMessage id="portfolio.tell_vision_title"/>
                        </h3>
                    </div>
                    <div className="col-lg-5 col-md-12 col-12 d-flex justify-content-lg-end justify-content-md-center justify-content-center">
                        <Link className="link-no-decoration" to="/contact-us/">
                            <Button
                                type="white"
                                label={intl.formatMessage({id: "portfolio.contact_us"})}
                            />
                        </Link>
                    </div>
                </div>
            </div>
            <DropBg
                src={TransparentDrop}
                right="-8%"
                bottom="-50%"
                mRight="-90%"
                zIndex="-1"
            />
        </div>
    )
}

export default FullWidthMessage
