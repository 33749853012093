import React from "react"
import {FormattedMessage} from "react-intl"
import styled from "styled-components"

const Title = styled.p`
    font-size: 0.8rem;
    font-weight: 600;
    font-family:'Proxima-nova'
    color:#ccc;
    span:first-child {
        color:#C0C0C1;
        letter-spacing: 1.75px;
        margin-right:6px;
        font-family:'Proxima-nova';
    }
    span:nth-child(2) {
        color:${({isSecondary, color}) => isSecondary ? '#B84FF8' : color};
        letter-spacing: 1.75px;
        font-family:'Proxima-nova'

    }
`

const SectionTitle = ({
    number,
    text,
    isSecondary,
    color = '#6A33A4'
}) => {
    return (
        <Title
            id={`${number}`}
            isSecondary={isSecondary}
            color={color}
        >
            <span>{number} |</span>
            <span>
                <FormattedMessage id={text}/>
            </span>
        </Title>
    )
}

export default SectionTitle
